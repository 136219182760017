import {api, config} from './api'

type RecordMetadata = {
  [metadataTag: string]: null | boolean | string | number[] | string[]
}

export type FrontSettings = Record<
  string,
  boolean | string | number | null | undefined
>

export type RecordFile = {
  id: number
  width: null | number
  height: number
  bitrate: null | number
  size: number
  error: null | unknown
  status: string
  name: string
  percent: null | number
  resource_type: string
}

type CommonResponseRecordData = RecordMetadata & {
  ad_template_id: number | null | undefined
  albums: {
    id: number
  }[]
  created_at: string
  current_screenshot: string | null | undefined
  current_screenshot_small: string | null | undefined
  description: string | null | undefined
  duration: number
  front_settings: null | FrontSettings
  id: number
  is_360: boolean | null | undefined
  is_hd: boolean | null | undefined
  is_processed: boolean | null | undefined
  name: string
  original_filename: null | string
  player_template_id: number | null | undefined
  recorded_at?: string
  shared_by?: string
  shared_records: {
    id: number
    account_id: number
  }[]
  tags: string[]
  updated_at: string
  upload_to_youtube: boolean
  user_email: string
  user_id: number
  uuid: string
  view_count: number
  youtube_id: string | null | undefined
  current_state: null | RecordUploadingState
  current_state_updated_at: null | string
  current_state_error_message: null | string
  is_reuploading: boolean
}

export type RecordUploadingState =
  | 'UPLOADING'
  | 'COMPLETING'
  | 'CONVERTING'
  | 'REPLICATING'
  | 'READY'
  | 'ERROR'

type PreviewLink = {
  w: number
  h: number
  url: string
}

export type RecordResponse = CommonResponseRecordData & {
  age_restrictions_type: number | null | undefined
  click_url: unknown | null | undefined
  country_access_template_id: number | null | undefined
  origin_size: number
  percent: number
  parent_account_name?: string
  parent_record_id?: number
  priority: unknown | null | undefined
  published_from: string | null | undefined
  published_until: string | null | undefined
  publication_period: number | null | undefined
  preview_links: PreviewLink[] | null
  preview_status: null | string
  preview_time_start: null | number
  record_files: RecordFile[]
  screenshot_id: number
  screenshots: string[]
  site_access_template_id: number | null | undefined
  subtitles: string[]
  shared_at?: string
  use_encryptor: null | boolean
  view_percent_by_the_end: void
  previews: {
    id: number
    time_start: number
    duration: number
    links: PreviewLink[]
    status: 'in_progress' | 'ready' | 'error'
  }[]
  current_preview_id: number | null
}

type RecordResponseWithUploadId = RecordResponse & {
  upload_id: string
}

/* records list */
export type FilterRecordsRequest = {
  sort?: string
  direction?: string
  page: number
  per_page: number
  album_id?: number
  albums_ids?: number[]
}

const processPayload = ({
  sort = 'created_at',
  direction = 'minus',
  albums_ids,
  ...other
}: FilterRecordsRequest): Record<string, any> => {
  const payload = {
    order: [sort, direction === 'plus' ? 'asc' : 'desc'].join('+'),
    ...other
  }

  if (Array.isArray(albums_ids)) {
    return {
      ...payload,
      albums_ids: JSON.stringify(albums_ids)
    }
  }

  return payload
}

export type RecordsItemResponse = CommonResponseRecordData & {
  convert_progress: number[]
  deleted_at?: string
  view_percent_by_the_end: number | null | undefined
}

export type RecordsItem = RecordsItemResponse & {
  album_ids: number[]
  convert_progress: number | null
  created_at: Date
  updated_at: Date
  deleted_at: Date | null
}

export const getFilterRecords = (
  filterId: number,
  payload: FilterRecordsRequest,
  signal?: AbortSignal
): Promise<{
  data: RecordsItemResponse[]
  total_pages: number
  total_records: number
}> =>
  api(
    `${config.auth}/api/v2/filters/${filterId}/records.json`,
    processPayload(payload),
    'GET',
    {
      signal
    }
  )

export const massDeleteRecords = (ids: number[]): Promise<void> => {
  const searchStr = ids
    .reduce((result, id) => {
      result.push(`ids[]=${id}`)

      return result
    }, [] as string[])
    .join('&')

  return api(
    `${config.auth}/api/v2/records/mass_delete.json?${searchStr}`,
    null,
    'DELETE'
  )
}

export const massUndeleteRecords = (ids: number[]): Promise<void> => {
  const searchStr = ids.map((id) => `ids[]=${id}`).join('&')

  return api(
    `${config.auth}/api/v2/records/mass_undelete.json?${encodeURI(searchStr)}`,
    null,
    'PUT'
  )
}

export const massUpdateRecords = (
  ids: number[],
  record: Record<
    string,
    null | number | boolean | string | string[] | number[]
  >,
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  } = {}
): Promise<void> => {
  const idsArr = ids.map((id) => `ids[]=${id}`)
  const dataArr = []

  for (const field in record) {
    if (record.hasOwnProperty(field)) {
      const value = record[field]

      if (Array.isArray(value)) {
        value.forEach((value) => dataArr.push(`record[${field}][]=${value}`))
      } else {
        dataArr.push(`record[${field}]=${String(value)}`)
      }
    }
  }

  return api(
    `${origin}/api/v2/records/mass_update.json?${encodeURI(
      idsArr.concat(dataArr).join('&')
    )}`,
    null,
    'PUT',
    {
      signal
    }
  )
}

export const getRecord = (
  recordId: number,
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  } = {}
): Promise<{
  data: RecordResponse
}> =>
  api(`${origin}/api/v2/records/${recordId}.json`, null, 'GET', {
    signal
  })

export type UpdateRecordRequest = RecordsItemResponse & {
  album_ids?: number[]
  tag_list?: string[]
  recorded_at?: string | null | undefined
  published_from?: string | null | undefined
  published_until?: string | null | undefined
}

export const putRecord = (
  recordId: number,
  record: Partial<UpdateRecordRequest>,
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  } = {}
): Promise<{
  data: RecordResponse
}> =>
  api(
    `${origin}/api/v2/records/${recordId}.json`,
    {
      record
    },
    'PUT',
    {
      signal
    }
  )

export const postRecord = (
  {
    upload_file_size,
    is_mute,
    ...record
  }: {
    name: string
    upload_file_size: number
    is_mute: boolean
    original_filename: string
  },
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  }
): Promise<{
  data: RecordResponseWithUploadId
}> =>
  api(
    `${origin}/api/v2/records.json`,
    {
      record,
      upload_file_size,
      s3_upload: true,
      is_mute
    },
    'POST',
    {signal}
  )

export const deleteRecord = (
  recordId: number,
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  } = {}
): Promise<void> =>
  api(
    `${origin}/api/v2/records/${recordId}.json`,
    {
      id: recordId
    },
    'DELETE',
    {signal}
  )

export const undeleteRecord = (recordId: number): Promise<void> =>
  api(
    `${config.auth}/api/v2/records/${recordId}/undelete.json`,
    {
      id: recordId
    },
    'PUT'
  )

export const putRecordFormData = (
  recordId: number,
  body: FormData
): Promise<{
  data: RecordResponse
}> =>
  api(`${config.auth}/api/v2/records/${recordId}`, null, 'PUT', {
    body
  })

export const uploadFile = (
  {
    id,
    upload_file_size,
    is_mute,
    ...record
  }: {
    id: number
    upload_file_size: number
    is_mute: boolean
    original_filename: string
  },
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  }
): Promise<{
  data: RecordResponseWithUploadId
}> =>
  api(
    `${origin}/api/v2/records/${id}/upload.json`,
    {
      record,
      upload_file_size,
      s3_upload: true,
      is_mute
    },
    'POST',
    {signal}
  )

export const shareRecord = (
  recordId: number,
  accountIds: number[]
): Promise<void> => {
  let accountsStr = accountIds
    .map((id) => `target_account_id[]=${id}`)
    .join('&')
  if (accountsStr) accountsStr = '?' + accountsStr

  return api(
    `${config.auth}/api/v2/records/${recordId}/share.json${accountsStr}`,
    {},
    'POST'
  )
}

export const massShareRecords = (
  recordIds: number[],
  accountIds: number[]
): Promise<void> =>
  api(
    `${config.auth}/api/v2/records/mass_share.json`,
    {
      ids: recordIds,
      target_account_id: accountIds
    },
    'PUT'
  )
