import React, {Suspense, lazy} from 'react'
import PageFallback from 'components/Page/Fallback'

const Broadcasts = lazy(
  () =>
    import(
      /* webpackChunkName: "Broadcasts" */
      './'
    )
)

const LazyBroadcasts: React.FC = () => (
  <Suspense fallback={<PageFallback />}>
    <Broadcasts />
  </Suspense>
)

export default LazyBroadcasts
