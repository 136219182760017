import 'styles/main.css'
import React, {Fragment} from 'react'
import {createRoot} from 'react-dom/client'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {
  ThemeProvider,
  globalJss,
  createTheme,
  colors,
  i18n
} from 'rambler-ui/theme'
import {activateSentry} from 'utils/sentry'
import {activateCollectMetrics} from 'utils/prometheus'
import App from 'containers/App'
import {Snackbar} from 'components/rc/snackbar'
import ErrorBoundary from 'components/ErrorBoundary'

activateSentry()
activateCollectMetrics()

// TODO remove redirect in some time
// let newHref
// redirection logic
// if (newHref) location.replace(newHref)

globalJss.setup({
  insertionPoint: document.getElementById('jss')
})

const theme = createTheme({
  i18n,
  colors,
  tagsInput: {
    types: {
      regular: {
        colors: {
          default: {
            text: 'currentColor',
            icon: 'currentColor'
          }
        }
      }
    }
  },
  tabs: {
    sidePadding: 15
  },
  hint: {
    colors: {
      icon: 'var(--rui-unnamedGray3)'
    }
  }
})

const root = document.getElementById('root')

const router = createBrowserRouter(
  [
    {
      path: '*',
      element: <App />
    }
  ],
  {
    basename: process.env.CDN_PREFIX
  }
)

if (root) {
  createRoot(root).render(
    <Fragment>
      <ErrorBoundary>
        <Helmet
          titleTemplate="%s — Рамблер/видеоплатформа"
          defaultTitle="Рамблер/видеоплатформа"
        />
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </ErrorBoundary>
      <Snackbar />
    </Fragment>
  )
}
