import React, {Component} from 'react'
import {type PartNumbers, completeUploading} from 'transport/v3/uploader'
import {wait} from 'utils/timeout'
import {
  type RecordResponse,
  type UpdateRecordRequest,
  putRecord,
  getRecord,
  postRecord,
  uploadFile,
  deleteRecord
} from 'transport/records'
import withPollingRecords, {
  type WithPollingRecordsProps
} from 'hoc/withPollingRecords'
import AppContext, {
  type ContextType as AppContextType
} from 'containers/App/context'
import UploadProviderContext, {
  type State,
  type Item,
  type ContextType,
  DEFAULT_STATE
} from './context'
import {
  getRecordPayload,
  getUpdatedData,
  ACCEPTED_TYPES,
  sendFile
} from './utils'
import {apiErrorCounter} from 'utils/prometheus'

type Props = WithPollingRecordsProps & {
  children?: React.ReactNode
}

const API_ERORR_CODES = [400, 502, 504]
const REQUEST_ERROR_RETRY_TIMEOUT = 15e3
const UPLOAD_COUNT_LIMIT = 3

class UploadRecordsProvider extends Component<Props, State> {
  static contextType = AppContext
  context: AppContextType

  state = DEFAULT_STATE

  abortControllers: Map<number, AbortController> = new Map() // itemId, AbortController
  itemIds: Map<number, number> = new Map() // recordId, itemId
  partNumbers: Map<number, PartNumbers> = new Map() // itemId, PartNumbers

  componentDidMount(): void {
    window.addEventListener('beforeunload', this.handlePageUnload)
  }

  unmounted = false

  componentWillUnmount(): void {
    this.unmounted = true
    window.removeEventListener('beforeunload', this.handlePageUnload)
    this.abortControllers.forEach((controller) => controller.abort())
    this.unsubscribePolling()
  }

  subscribedRecordPollingIds: Map<number, true> = new Map()

  subscribePolling = (apiOrigin: string, id: number): void => {
    if (this.subscribedRecordPollingIds.size === 0)
      this.context.emitter.on('recordsPolling', this.handleRecordsPolling, this)
    this.props.pollingRecords.subscribe(apiOrigin, id)
    this.subscribedRecordPollingIds.set(id, true)
  }

  unsubscribePolling = (ids?: number[]): void => {
    const unsubscribeIds = ids
      ? ids.filter((id) => this.subscribedRecordPollingIds.has(id))
      : Array.from(this.subscribedRecordPollingIds.keys())

    if (unsubscribeIds.length === 0) return

    this.props.pollingRecords.unsubscribe(...unsubscribeIds)
    unsubscribeIds.forEach((id) => this.subscribedRecordPollingIds.delete(id))

    if (this.subscribedRecordPollingIds.size === 0) {
      this.context.emitter.removeListener(
        'recordsPolling',
        this.handleRecordsPolling,
        this
      )
    }
  }

  handlePageUnload = (ev: Event): void | string => {
    const {data, list} = this.state

    if (list.some((id) => data[id].status !== 'COMPLETED')) {
      ev.preventDefault()

      const message = 'Загрузки на завершенны'

      ev.returnValue = message

      return message
    }
  }

  handleRecordsPolling(pollingData: Record<number, RecordResponse>): void {
    const {data} = this.state
    const unsubscribeIds: number[] = []

    this.subscribedRecordPollingIds.forEach((_, id) => {
      const itemId = this.itemIds.get(id)
      const item = itemId != null ? data[itemId] : null
      const pollingItem = pollingData[id] as undefined | RecordResponse

      if (itemId != null && item && pollingItem) {
        const uploaderStatus = pollingItem.current_state
        let completedAt: Date | null = null

        if (uploaderStatus !== item.uploaderStatus) {
          if (
            uploaderStatus === 'READY' &&
            pollingItem.current_state_updated_at
          ) {
            unsubscribeIds.push(id)
            completedAt = new Date(pollingItem.current_state_updated_at)
          }

          this.setState((state) => ({
            data: getUpdatedData(state, itemId, {uploaderStatus, completedAt})
          }))
        }
      }
    })

    if (unsubscribeIds.length) this.unsubscribePolling(unsubscribeIds)
  }

  getItemId = (() => {
    let lastId = 0

    return () => lastId++
  })()

  uploadFiles: ContextType['uploadFiles'] = async ({
    files,
    accountName,
    accountCode,
    apiOrigin,
    replacedRecordId,
    soundDisabled = false
  }) => {
    if (replacedRecordId != null && files.length > 1) {
      this.context.openSnackbar({
        message: 'Необходимо выбрать 1 файл',
        type: 'error'
      })

      return
    }

    for (const file of files) {
      const [type, subtype] = file.type.split('/')
      const isMxf =
        file.type === 'application/mxf' ||
        (file.type === '' && file.name.endsWith('.mxf'))
      const isMkv = file.type === '' && file.name.endsWith('.mkv')
      const isValidFormat =
        isMxf ||
        isMkv ||
        (type === 'video' && subtype && ACCEPTED_TYPES.includes(subtype))

      if (!isValidFormat) {
        this.context.openSnackbar({
          message: `Неверный формат файла ${file.name}`,
          type: 'error'
        })

        return
      }
    }

    const newItemsList: State['list'] = []
    const newItemsData: State['data'] = {}

    for (const file of files) {
      const itemId = this.getItemId()

      newItemsList.push(itemId)
      newItemsData[itemId] = {
        id: itemId,
        progress: 0,
        status: null,
        uploaderStatus: null,
        completedAt: null,
        uploadId: null,
        recordId: null,
        accountName,
        accountCode,
        apiOrigin,
        replacedRecordId: replacedRecordId ?? null,
        file,
        soundDisabled
      }
    }

    this.setState(
      (state) => ({
        list: [...newItemsList, ...state.list],
        data: {...newItemsData, ...state.data},
        queue: [...state.queue, ...newItemsList]
      }),
      this.uploadNext
    )
  }

  async sendFile(itemId: number): Promise<void> {
    const item = this.state.data[itemId]

    if (item) {
      const {
        replacedRecordId,
        file,
        soundDisabled,
        apiOrigin: origin,
        accountCode
      } = item
      let {uploadId} = item
      const abortController = new AbortController()
      const {signal} = abortController

      this.abortControllers.set(itemId, abortController)
      this.setState((state) => ({
        data: getUpdatedData(state, itemId, {
          status: 'IN_PROGRESS'
        }),
        queue: state.queue.filter((i) => i !== itemId)
      }))

      if (replacedRecordId != null)
        this.context.openSnackbar({
          type: 'success',
          autoCloseDuration: 5e3,
          message:
            'Замена видеофайла началась в фоновом режиме. Обновите страницу через несколько минут для просмотра'
        })

      try {
        let recordId

        if (!uploadId) {
          const newData: Partial<Item> = {}
          const recordPayload = {
            ...getRecordPayload(file),
            is_mute: soundDisabled
          }

          while (true) {
            try {
              if (replacedRecordId) {
                const {
                  name, // eslint-disable-line @typescript-eslint/no-unused-vars
                  ...restPayload
                } = recordPayload
                const response = await uploadFile(
                  {
                    ...restPayload,
                    id: replacedRecordId
                  },
                  {
                    signal,
                    origin
                  }
                )

                uploadId = response.data.upload_id
                recordId = replacedRecordId
              } else {
                const {data} = await postRecord(recordPayload, {
                  signal,
                  origin
                })

                uploadId = data.upload_id
                recordId = data.id
                newData.recordId = data.id
                this.context.emitter.emit('updateRecordsList', accountCode)
              }

              break
            } catch (err: any) {
              if (err.code === 0) {
                await wait(REQUEST_ERROR_RETRY_TIMEOUT, signal)
              } else {
                throw err
              }
            }
          }

          newData.uploadId = uploadId
          this.itemIds.set(recordId, itemId)

          this.setState((state) => ({
            data: getUpdatedData(state, itemId, newData)
          }))
        } else {
          recordId = replacedRecordId ?? item.recordId
        }

        let partNumbers
        const timeouts = [1e3, 5e3, 10e3]

        while (true) {
          try {
            partNumbers = await sendFile({
              uploadId,
              file,
              partNumbers: this.partNumbers.get(itemId),
              onProgress: (progress) => {
                this.setState((state) => ({
                  data: getUpdatedData(state, itemId, {progress})
                }))
              },
              onChunkLoaded: (partNumbers) => {
                this.partNumbers.set(itemId, partNumbers)
              },
              signal
            })
            break
          } catch (err: any) {
            if (err.name !== 'AbortError') {
              apiErrorCounter.inc(1, {
                endpoint: '/api/uploader/upload',
                errorCode: err.code != null ? String(err.code) : 'unknown'
              })
            }

            if (err.code === 0) {
              await wait(REQUEST_ERROR_RETRY_TIMEOUT, signal)
            } else if (
              timeouts.length > 0 &&
              API_ERORR_CODES.includes(err.code)
            ) {
              await wait(timeouts.shift() as number, signal)
            } else {
              throw err
            }
          }
        }

        while (true) {
          try {
            await completeUploading(
              {
                uploadUuid: uploadId,
                partNumbers
              },
              signal
            )
            break
          } catch (err: any) {
            if (err.code === 0) {
              await wait(REQUEST_ERROR_RETRY_TIMEOUT, signal)
            } else {
              throw err
            }
          }
        }

        this.setState(
          (state) => ({
            data: getUpdatedData(state, itemId, {
              status: 'COMPLETED'
            })
          }),
          this.uploadNext
        )

        if (recordId) {
          this.subscribePolling(origin, recordId)
        }

        if (replacedRecordId) {
          try {
            const {
              data: {front_settings}
            } = await getRecord(replacedRecordId, {origin})

            if (front_settings?.episodes) {
              delete front_settings.episodes
              await putRecord(
                replacedRecordId,
                {front_settings} as Partial<UpdateRecordRequest>,
                {origin}
              )
            }
          } catch {}
        }
      } catch (err: any) {
        if (this.unmounted) return
        const aborted = err.name === 'AbortError'

        this.setState(
          (state) => ({
            data: getUpdatedData(state, itemId, {
              status: aborted ? 'ABORTED' : 'FAILED'
            })
          }),
          this.uploadNext
        )

        if (!aborted) {
          this.context.handleException(err)
          console.error(err)
        }
      } finally {
        this.abortControllers.delete(itemId)
      }
    }
  }

  abortUploading: ContextType['abortUploading'] = (itemId) => {
    this.abortControllers.get(itemId)?.abort()
  }

  retryUploading: ContextType['retryUploading'] = async (itemId) => {
    this.setState(
      (state) => ({
        data: getUpdatedData(state, itemId, {
          status: null
        }),
        queue: state.queue.concat(itemId)
      }),
      this.uploadNext
    )
  }

  uploadNext = (): void => {
    const {queue, data, list} = this.state

    const startedCount = list.filter((itemId) => {
      const item = data[itemId]

      return item && item.status === 'IN_PROGRESS'
    }).length

    const shouldUploadCount = UPLOAD_COUNT_LIMIT - startedCount

    if (shouldUploadCount > 0) {
      queue.slice(0, shouldUploadCount).forEach((itemId) => {
        this.sendFile(itemId)
      })
    }
  }

  deleteItem: ContextType['deleteItem'] = async (
    itemId,
    shouldDeleteRecord
  ) => {
    const {
      recordId,
      replacedRecordId,
      apiOrigin: origin,
      accountCode
    } = this.state.data[itemId]

    if (recordId) {
      if (shouldDeleteRecord) {
        try {
          await deleteRecord(recordId, {origin})
        } catch (err: any) {
          this.context.handleException(err)
          throw err
        }

        this.context.emitter.emit('updateRecordsList', accountCode)
      }

      this.itemIds.delete(recordId)
    }

    const id = recordId ?? replacedRecordId
    if (id) this.unsubscribePolling([id])
    this.abortControllers.delete(itemId)
    this.partNumbers.delete(itemId)
    this.setState((state) => {
      const data = {...state.data}

      delete data[itemId]

      return {
        list: state.list.filter((id) => id !== itemId),
        data,
        queue: state.queue.filter((id) => id !== itemId)
      }
    })
  }

  getItemByRecordId = (recordId: number): Item | void => {
    const itemId = this.itemIds.get(recordId) ?? -1

    return this.state.data[itemId]
  }

  render(): JSX.Element {
    return (
      <UploadProviderContext.Provider
        value={{
          ...this.state,
          uploadFiles: this.uploadFiles,
          abortUploading: this.abortUploading,
          retryUploading: this.retryUploading,
          deleteItem: this.deleteItem,
          getItemByRecordId: this.getItemByRecordId
        }}>
        {this.props.children}
      </UploadProviderContext.Provider>
    )
  }
}

export default withPollingRecords(UploadRecordsProvider)
