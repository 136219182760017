import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo
} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {getAccountsVisits} from 'utils/accountsVisits'
import ArrowDownSmallIcon from 'components/icons/redesigned/arrowDownSmall.svg'
import ArrowUpSmallIcon from 'components/icons/redesigned/arrowUpSmall.svg'
import {DropdownWrapper, Dropdown} from 'components/rc/dropdown'
import CustomScrollbar from 'components/CustomScrollbar'
import MenuItem from 'components/Menu/Item'
import IconText from 'components/IconText'
import ContentButton from 'components/ContentButton'
import AppContext from 'containers/App/context'
import styles from './styles.css'

const AccountsDropdown: React.FC = () => {
  const [isOpen, setState] = useState(false)
  const close = useCallback(() => setState(false), [])
  const open = useCallback(() => setState(true), [])

  const {pathname, search} = useLocation()

  const {accountsList, permissions, getAccountPermissions, getPath, emitter} =
    useContext(AppContext)

  useEffect(() => {
    emitter.on('accountFetchingError', open)

    return () => {
      emitter.removeListener('accountFetchingError', open)
    }
  }, [emitter, open])

  const [frequentlyVisitedAccounts, allAccounts] = useMemo(() => {
    if (!permissions) return []

    const accountCodes = new Map(accountsList.map((code) => [code, true]))
    const frequentlyVisited = Object.fromEntries(
      Object.entries(getAccountsVisits())
        .filter(([code]) => accountCodes.has(code))
        .sort(([, a], [, b]) => b - a)
        .slice(0, 5)
    )

    const frequentlyVisitedAccounts: string[] = []
    const allAccounts: string[] = []

    const collator = new Intl.Collator('ru-RU', {
      sensitivity: 'accent'
    })

    accountsList
      .slice()
      .sort((a: string, b: string) =>
        collator.compare(
          permissions[a].accountName.trim(),
          permissions[b].accountName.trim()
        )
      )
      .forEach((code) => {
        if (code in frequentlyVisited) frequentlyVisitedAccounts.push(code)
        allAccounts.push(code)
      })

    return [frequentlyVisitedAccounts, allAccounts]
  }, [accountsList, permissions])

  if (!allAccounts?.length || !permissions) return null

  const {accountName, accountCode} = getAccountPermissions()

  // Сквозная навигация м/у разделами статистики по аккаунтам
  const accountMainPath =
    accountName != null && pathname.startsWith(getPath('stat'))
      ? pathname.replace(getPath('') + '/', '') + search
      : 'records'

  const getLinkByCode = (code: string): React.ReactNode => (
    <MenuItem
      key={code}
      container={<Link to={getPath(accountMainPath, code)} />}
      selected={code === accountCode}
      onClick={close}>
      {permissions[code].accountName}
    </MenuItem>
  )

  return (
    <DropdownWrapper className={styles.dropdownWrapper}>
      <IconText
        className={styles.accountsAnchor}
        container={<ContentButton />}
        iconComponent={isOpen ? ArrowUpSmallIcon : ArrowDownSmallIcon}
        onClick={isOpen ? close : open}
        rightIcon>
        {accountName || 'Аккаунт'}
      </IconText>
      <Dropdown
        className={styles.dropdown}
        position="left"
        minWidth={200}
        isOpen={isOpen}
        onClose={close}
        display="bright">
        <CustomScrollbar className={styles.scrollContent}>
          {!!frequentlyVisitedAccounts?.length && (
            <Fragment>
              <h5 className={styles.dropdownHeading}>Часто посещаемые</h5>
              {frequentlyVisitedAccounts.map(getLinkByCode)}
              <h5 className={styles.dropdownHeading}>Все аккаунты</h5>
            </Fragment>
          )}

          {allAccounts.map(getLinkByCode)}
        </CustomScrollbar>
      </Dropdown>
    </DropdownWrapper>
  )
}

export default AccountsDropdown
