import React, {useContext, useCallback, useMemo, useRef, useEffect} from 'react'
import {getRecord, type RecordResponse} from 'transport/records'
import {wait} from 'utils/timeout'
import AppContext from 'containers/App/context'
import Context from './context'

type Props = {
  children: React.ReactNode
}

const NOOP = () => {} // eslint-disable-line

const PollingRecordsProvider: React.FC<Props> = (props) => {
  const {emitter} = useContext(AppContext)
  const abortController = useRef<AbortController>()
  const pollingRecords = useRef<Map<number, {count: number; origin: string}>>(
    new Map()
  )

  const startPolling = useCallback(async (): Promise<void> => {
    const {signal} = (abortController.current = new AbortController())

    while (true) {
      try {
        await wait(5e3, signal)

        const data: Record<number, RecordResponse> = {}

        await Promise.all(
          Array.from(pollingRecords.current, ([id, {origin}]) =>
            getRecord(id, {signal, origin}).then((response) => {
              data[id] = response.data
            }, NOOP)
          )
        )

        emitter.emit('recordsPolling', data)
      } catch (err: any) {
        if (err.name !== 'AbortError') {
          throw err
        } else {
          break
        }
      }
    }
  }, [emitter])

  const stopPolling = useCallback(() => {
    abortController.current?.abort()
    abortController.current = undefined
  }, [])

  const subscribe = useCallback(
    (origin: string, ...ids: number[]): void => {
      ids.forEach((id) => {
        pollingRecords.current.set(id, {
          count: (pollingRecords.current.get(id)?.count ?? 0) + 1,
          origin
        })
      })

      if (!abortController.current) startPolling()
    },
    [startPolling]
  )

  const unsubscribe = useCallback((...ids: number[]): void => {
    ids.forEach((id) => {
      const item = pollingRecords.current.get(id)
      if (!item) return

      const subscribersCount = item.count - 1

      if (subscribersCount < 1) {
        pollingRecords.current.delete(id)
      } else {
        item.count = subscribersCount
      }
    })

    if (pollingRecords.current.size === 0) stopPolling()
  }, [])

  const contextValue = useMemo(
    () => ({
      subscribe,
      unsubscribe
    }),
    [subscribe]
  )

  useEffect(() => {
    return () => stopPolling()
  }, [])

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  )
}

export default PollingRecordsProvider
